import { AxiosInstance } from 'axios';
import { useQuery, useMutation } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';
import { capitalize } from 'src/util/stringFormat';

import { CreateDeviceOrderPayload, PendingOrderRes } from '../interfaces/Subscriptions.types';

export const SubscriptionsService = (axios: AxiosInstance) => {
  const useGetSubscriptions = () => {
    const getSubscriptions = () => axios.get(
      `${apiUrlV4}subscriptions`,
    ).then((response) => {
      return response.data.data;
    });

    return useQuery(
      'query-subscriptions',
      getSubscriptions,
      {
        onError: (error) => {
          snackbarService.error('Error getting subscriptions. Try again later.');
          console.error('Error when trying to get the subscriptions:', error);
        },
        refetchOnWindowFocus: false,
      },
    );
  };

  const useGetOrganizationTypes = () => {
    const getOrganizationTypes = () => axios.get(
      `${apiUrlV4}organization-types`,
    ).then((response) => {
      return response.data.data.filter((o) => o.name.toLowerCase() !== 'other')
        .map((organization) => ({ label: organization.name, value: organization._id }))
        .concat({ label: 'Other', value: 'other' });
    });

    return useQuery(
      'query-organization-types',
      getOrganizationTypes,
      {
        onError: (error) => {
          snackbarService.error('Error getting organization types. Try again later.');
          console.error('Error when trying to get the organization types:', error);
        },
        refetchOnWindowFocus: false,
      },
    );
  };

  const useGetScoreboardControllers = () => {
    const getScoreboardControllers = () => axios.get(
      `${apiUrlV4}scoreboard-controllers`,
    ).then((response) => {
      return response.data.data.map((controller) => ({
        label: controller.name,
        value: controller._id,
        models: controller.models.map((model) => ({
          label: model.name,
          value: model.name,
          sports: model.sports.map((sport) => ({
            label: capitalize(sport),
            value: sport,
          }))
            .concat({ label: 'Other', value: 'other' }),
        })),
      }));
    });

    return useQuery(
      'query-scoreboard-controllers',
      getScoreboardControllers,
      {
        onError: (error) => {
          snackbarService.error('Error getting scoreboard controllers. Try again later.');
          console.error('Error when trying to get the scoreboard controllers:', error);
        },
        refetchOnWindowFocus: false,
      },
    );
  };

  const useCreatePaymentIntent = () => {
    const createPaymentIntent = (payload) => axios.post(`${apiUrlV4}device-order/payment-intent`, payload);

    return useMutation(
      'mutation-payment-intent',
      createPaymentIntent,
      {
        onError: (error) => {
          console.error('Error in create payment intent:', error);
          snackbarService.error("Can't create payment intent. Please, try later.");
        },
      },
    );
  };

  const useValidatePromoCode = () => {
    const validatePromoCode = (payload) => axios.post(`${apiUrlV4}device-order/promo-code/validate`, payload)
      .then((res) => {
        if (res.data.is_valid_code) {
          snackbarService.success(res.data.message);
        }
        return res.data;
      });

    return useMutation(
      'mutation-validate-promo-code',
      validatePromoCode,
      {
        onError: (error) => {
          console.error('Error validating promo code:', error);
          snackbarService.error("Can't validate promo code. Please, try later.");
        },
      },
    );
  };

  const useCreateDeviceOrder = () => {
    const createDeviceOrder = (payload: CreateDeviceOrderPayload) => axios.post(`${apiUrlV4}device-order`, payload)
      .then((res) => res.data);

    return useMutation(
      'mutation-create-device-order',
      createDeviceOrder,
      {
        onError: (error) => {
          console.error('Error in create device order:', error);
          snackbarService.error("Can't create device order. Please, try later.");
        },
      },
    );
  };

  const useGetPendingOrder = (idOrEmail: string) => {
    const getPendingOrder = async () => {
      const isEmail = idOrEmail.includes('@');
      const endpoint = `${apiUrlV4}device-order/${idOrEmail}${isEmail ? '/pending' : ''}`;
      const res = await axios.get(endpoint);

      const resData: PendingOrderRes = res.data;
      return resData;
    };

    return useQuery({
      queryKey: ['query-get-pending-order', idOrEmail],
      queryFn: getPendingOrder,
      onError: (error) => {
        console.error('Error in get pending order:', error);
        snackbarService.error("Can't get pending order. Please, try later.");
      },
      enabled: !!idOrEmail,
      refetchOnWindowFocus: false,
    });
  };

  return {
    useGetSubscriptions,
    useGetOrganizationTypes,
    useGetScoreboardControllers,
    useCreatePaymentIntent,
    useValidatePromoCode,
    useCreateDeviceOrder,
    useGetPendingOrder,
  };
};
