interface Settings {
  widgetSgShowSchedules: boolean;
  primaryColor: string;
  secondaryColor: string;
  logoURL: string;
  logoUrlSmall: string;
  mascot: string;
}

export interface SchoolData {
  name: string;
  displayName: string;
  settings: Settings;
}

interface FacilityData {
  name: string;
  deviceSerial: string;
}

interface GameState {
  away: string;
  home: string;
  quarter: string;
  time: string;
  ts: string;
}

interface MappedGameState {
  away: string;
  home: string;
  gameTime: string;
}

export interface GameData {
  state: GameState;
  mappedState: MappedGameState;
}

interface DeviceInfo {
  interface: string;
}

interface DeviceData {
  serial: string;
  firmwareVersion: string;
  lastOnlineTs: string;
  online: boolean;
  deviceInfo: DeviceInfo;
  shadow: string;
  connectionType: ConnectionType;
}

export interface Game {
  id: string;
  homeSchoolId: string;
  awaySchoolId: string | null;
  gameStartTs: string;
  name: string;
  homeName: string;
  sport: string;
  grade: string;
  gender: string;
  awayName: string;
  gameId: string;
  homeSchoolData: SchoolData | null;
  awaySchoolData: SchoolData | null;
  facilityData: FacilityData;
  gameData: GameData;
  deviceData: DeviceData;
  flagged: boolean;
  noAutoFinalize: boolean;
}

export enum ConnectionType {
  Ethernet = 'Ethernet',
  WiFi = 'WiFi',
  Cellular = 'Cellular',
}

// TODO remove when API is ready
export const MOCK_GAME: Game = {
  id: '66a100699ea5370008a06702',
  homeSchoolId: '6604ba123a704c0029f04d48',
  awaySchoolId: null,
  gameStartTs: '2024-08-19T19:30:55Z',
  name: 'Lockford Park Real vs TBD',
  homeName: 'Lockford Park Real',
  sport: 'basketball',
  grade: '8th grade',
  gender: 'boys',
  awayName: 'TBD',
  gameId: 'game:05885363-1f29-46f8-bb7a-e42956b7b4bb',
  homeSchoolData: {
    name: 'Lockford Park Real',
    displayName: '',
    settings: {
      widgetSgShowSchedules: false,
      primaryColor: '',
      secondaryColor: '',
      logoURL:
          'https://cdn4.iconfinder.com/data/icons/miu-square-flat-social/60/whatsapp-square-social-media-512.png',
      logoUrlSmall: '',
      mascot: '',
    },
  },
  awaySchoolData: {
    name: 'AWAY Lockford Park Real',
    displayName: 'New Team',
    settings: {
      widgetSgShowSchedules: false,
      primaryColor: '',
      secondaryColor: '',
      logoURL:
          'https://cdn4.iconfinder.com/data/icons/miu-square-flat-social/60/whatsapp-square-social-media-512.png',
      logoUrlSmall: '',
      mascot: '',
    },
  },
  facilityData: {
    name: "Byron's v3.1 device",
    deviceSerial: '30216',
  },
  gameData: {
    state: {
      away: '',
      home: '',
      quarter: '0',
      time: '00:38',
      ts: '2024-08-19T18:27:58.231Z',
    },
    mappedState: {
      home: '13',
      away: '15',
      gameTime: '1st, 00:02',
    },
  },
  deviceData: {
    serial: '30216',
    firmwareVersion: '3.1.1-pre350',
    lastOnlineTs: '2024-08-20T16:45:10.154Z',
    online: true,
    deviceInfo: {
      interface: 'eth0',
    },
    shadow: 'simulated.basketball',
    connectionType: ConnectionType.Cellular,
  },
  flagged: false,
  noAutoFinalize: false,
};

export enum GamesAdminTab {
  AllGames = 'allGames',
  Finalized = 'finalized',
  DevicesOnlineWOGame = 'devicesOnlineWOGame',
  DevicesOnlineWGame = 'devicesOnlineWGame',
  GamesWNotes = 'gamesWNotes',
}

export const GamesAdminTabLabel: Record<GamesAdminTab, string> = {
  [GamesAdminTab.AllGames]: 'All Games',
  [GamesAdminTab.Finalized]: 'Finalized',
  [GamesAdminTab.DevicesOnlineWOGame]: 'Devices Online w/o Game',
  [GamesAdminTab.DevicesOnlineWGame]: 'Devices Online w/Game',
  [GamesAdminTab.GamesWNotes]: 'Games w/Notes',
};

export enum GameCategory {
  Flagged = 'flagged',
  Live = 'live',
  Upcoming = 'upcoming',
  Final = 'final',
}

export const GameCategoryLabel: Record<GameCategory, string> = {
  [GameCategory.Flagged]: 'Flagged',
  [GameCategory.Live]: 'Live',
  [GameCategory.Upcoming]: 'Upcoming',
  [GameCategory.Final]: 'Final',
};

export type GamesAdminData = Record<GameCategory, Game[]>;

export const MOCK_GAMES_ADMIN_DATA: GamesAdminData = {
  [GameCategory.Flagged]: Array.from({ length: 10 }, () => ({ ...MOCK_GAME })),
  [GameCategory.Live]: [],
  [GameCategory.Upcoming]: [],
  [GameCategory.Final]: [MOCK_GAME],
};
