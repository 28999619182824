import React, { Suspense } from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
} from 'react-router-dom';
import ErrorModal from 'src/components/common/modals/error-modal/ErrorModal';
import { SocketProvider } from 'src/context/socket';

import './styles/variables.css';
import App from './App';
import { Snackbar } from './components/common/snackbar/Snackbar';
import Spinner from './components/common/spinner/Spinner';
import { store } from './store/store';

const queryClient = new QueryClient();

Sentry.init({
  dsn: 'https://6f9d84caf55c43d5bf1b121938be7804@o1078332.ingest.sentry.io/6082186',
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: !window.location.href.includes('localhost') && !window.location.href.includes('staging'),
});

/* Google Tag Manager support */
TagManager.initialize({
  gtmId: 'GTM-T7WFCZJ',
});

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <SocketProvider>
          <BrowserRouter>
            <SnackbarProvider>
              <Snackbar />
              <Suspense fallback={<div className="loader"><Spinner /></div>}>
                <App />
              </Suspense>
            </SnackbarProvider>
          </BrowserRouter>
        </SocketProvider>
      </QueryClientProvider>
      <ErrorModal />
    </Provider>
  </React.StrictMode>,
);
