import React, { useState } from 'react';

import cn from 'classnames';
import { useSelector } from 'react-redux';
import { MenuItem } from 'src/components/common/menuItem/MenuItem';
import { SelectCountry } from 'src/components/common/select-country/SelectCountry';
import { SelectInput } from 'src/components/common/select/Select';
import { useGetAsideMenu } from 'src/components/customer/aside-menu/useGetAsideMenu';
import './styles.scss';

const Menu = (props) => {
  const {
    onNavClick,
    states,
    associations,
    schools,
    selectedCountry,
    selectedState,
    selectedGroup,
    selectedSchool,
    schoolsIsLoading,
    onCountrySelect,
    onStateSelect,
    onGroupSelect,
    onSchoolSelect,
    expandable = false
  } = props;
  const { currentUser } = useSelector((state) => state.currentUser);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const menu = useGetAsideMenu(currentUser);

  return (
    <div className={cn('menu-bar', menuIsOpen ? 'open-menu' : 'close-menu', { expandable })}>
      <div className="current-selector">
        {currentUser?.super_admin && (
          <>
            <SelectCountry
              menuBar
              className="menu-dropdown"
              value={selectedCountry}
              onChange={onCountrySelect}
            />
            <SelectInput
              menuBar
              className="menu-dropdown"
              options={states}
              placeholder="Select State"
              defaultValue={selectedState}
              value={selectedState}
              onChange={onStateSelect}
              isDisabled={selectedGroup}
              isClearable
              label=""
            />
            {associations?.filter((g) => g.data?.state === selectedState?.value).length
            > 0 && (
            <SelectInput
              menuBar
              className="menu-dropdown"
              options={associations?.filter(
                (g) => g.data?.state === selectedState?.value,
              )}
              placeholder="Select Group"
              value={selectedGroup}
              isLoading={schoolsIsLoading || !associations}
              onChange={onGroupSelect}
              isClearable
              label=""
            />
            )}
          </>
        )}
        <SelectInput
          menuBar
          className="menu-dropdown"
          options={schools}
          defaultValue={selectedSchool}
          value={selectedSchool}
          placeholder="Select School"
          onChange={onSchoolSelect}
          isLoading={schoolsIsLoading}
          isClearable={false}
          label=""
        />
      </div>
      {currentUser && menu.map((item) => (
        <MenuItem
          key={item.link}
          link={item.link}
          onClick={onNavClick}
          title={item.title}
          img={item.icon}
          newFlag={item.newFlag}
        />
      ))}
      <button onClick={() => setMenuIsOpen(!menuIsOpen)} type="button" className={cn('menu-bar-open-button', { expandable })}>{menuIsOpen ? 'Hide menu' : 'Expand menu'}</button>
    </div>
  );
};

export default Menu;
