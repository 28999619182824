/* Known issue - braking buttons css of imports is rearranged */
/* Bad css configurations */
import './App.scss';
import React, {
  useEffect, useState, useCallback,
} from 'react';

import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StatusEnums } from 'src/enums/StatusEnums';

import { NetworkError } from './components/common/other-pages/NetworkError';
import { snackbarService } from './components/common/snackbar/snackbar-service';
import { firebaseAuth } from './fb-configuration/db-firebase';
import ScoreBirdRouter from './router/ScoreBirdRouter';
import api from './services/api';
import { UserLogOut } from './services/auth';
import { ActionTypes } from './store/reducers/currentUserReducer';
import { RootState } from './store/types/rootStateTypes';
import { getIdToken } from './util/getIdToken';

const { CURRENT_USER_IS_USER_APPROVED } = ActionTypes;
const { ACCEPTED } = StatusEnums;

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth, authLoaded } = useSelector((store: RootState) => store.token);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  const { GetUserDataByEmail } = api.userService();
  const { mutateAsync: getUserDataByEmail } = GetUserDataByEmail();

  /* For mobile app ----------------------------------------------------------*/
  const handleDeepLink = useCallback(() => {
    const url = new URL(window.location.href.replace('/#', ''));
    const fromMobile = new URLSearchParams(url.search).get('deepLink');

    if (fromMobile) {
      const platform = /iPhone|iPad|iPod/.test(navigator.userAgent)
        ? 'Apple'
        : /Android/.test(navigator.userAgent)
          ? 'Android'
          : 'Other';
      switch (platform) {
        case 'Apple':
          // @ts-ignore
          window.location = `scorebirdmanagementapp://${fromMobile}`;
          setTimeout(() => {
            window.location.href = 'https://itunes.apple.com/us/app/scorebirdmanagementapp/id1472227801?ls=1&mt=8';
          }, 1000);
          break;
        case 'Android':
          // @ts-ignore
          window.location = `scorebirdmanagementapp://${fromMobile}`;
          setTimeout(() => {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.scorebird.customermanagement';
          }, 1000);
          break;
        default:
          break;
      }
    }
  }, []);

  useEffect(() => {
    handleDeepLink();
  }, [handleDeepLink]);
  /*--------------------------------------------------------------------------*/

  const handleReload = useCallback(() => {
    if (navigator.onLine) {
      window.location.reload();
    }
  }, []);

  const updateOnlineStatus = useCallback(() => {
    const networkError = document.getElementById('network');
    const app = document.getElementById('app');
    if (navigator.onLine) {
      app.style.display = 'block';
      networkError.style.display = 'none';
    } else {
      app.style.display = 'none';
      networkError.style.display = 'block';
    }
  }, []);

  useEffect(() => {
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, [updateOnlineStatus]);

  useEffect(() => {
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = 'cabdde75-39e1-41e6-bb00-c4e2f4fda2f5';
    ((() => {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = Boolean(1); d.getElementsByTagName('head')[0].appendChild(s);
    })());
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (userData) => {
      if (userData?.email) {

        getIdToken().then((idToken) => {
          if (idToken !== null) {
            dispatch({
              type: 'currentUser/setUserEmail',
              payload: userData.email,
            });
            dispatch({
              type: 'token/set',
              payload: idToken,
            });

            getUserDataByEmail(userData.email).then((response) => {
              if (response.success) {
                try {
                  // @ts-ignore
                  window.$crisp?.push(['set', 'user:email', [response.data.email]]);
                  // @ts-ignore
                  window.$crisp?.push(['set', 'user:nickname', [` ${response.data.first_name} ${response.data.last_name}`]]);
                } catch (e) {
                  console.group('Error in Crisp set email or nickname');
                  console.log(e);
                  console.groupEnd();
                }
                dispatch({ type: 'currentUser/set', payload: response.data });
                if (!response.data.selected_schools) {
                  navigate('/sign-up', { state: { email: response.data.email, step: 1 } });
                }
              } else {
                snackbarService.error('User profile can not be loaded, please try again later.');
                setTimeout(() => {
                  dispatch({ type: 'currentUser/remove' });
                  UserLogOut();
                }, 2000);
              }

              // Old users don't have the join_request
              const emptyJointRequest = !response.data?.join_request;
              const isAccepted = response.data?.join_request === ACCEPTED;
              const isUserApproved = isAccepted || emptyJointRequest;

              dispatch({
                type: CURRENT_USER_IS_USER_APPROVED,
                payload: isUserApproved,
              });

              setIsSuperAdmin(!!response.data?.super_admin);
              dispatch({
                type: 'token/setIsAuth',
                payload: true,
              });
              dispatch({
                type: 'token/setAuthLoaded',
                payload: true,
              });
            });
          }
        });
      } else {
        dispatch({
          type: 'token/setIsAuth',
          payload: false,
        });
        dispatch({
          type: 'token/setAuthLoaded',
          payload: true,
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const isNavigatorOnLine = navigator.onLine;

  return (
    <>
      <div id="app" style={{ display: isNavigatorOnLine ? 'block' : 'none' }}>
        {
          authLoaded && <ScoreBirdRouter isAuth={isAuth} isSuperAdmin={isSuperAdmin} />
        }
      </div>
      <div id="network" style={{ display: isNavigatorOnLine ? 'none' : 'block' }}>
        <NetworkError onReload={handleReload} />
      </div>
    </>
  );
};

export default App;
