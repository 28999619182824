import { createAction } from '@reduxjs/toolkit';

import { deviceOrderActionTypes } from '../actionTypes/deviceOrderActionTypes';

const {
  CONTACT_INFO,
  BILLING_INFO,
  CART,
  PAY_VIA,
  PREV_CART_TOTAL,
  CURRENT_CART_TOTAL,
  CART_TOTAL_WITH_DISCOUNT,
  PROMO_CODE,
  DISCOUNT,
  DISCOUNT_TYPE,
  STRIPE_TOKEN,
  PAYLOAD,
  RESET_DEVICE_ORDER,
  ORDER_ID,
  PENDING_ORDER_DATA,
} = deviceOrderActionTypes;

export const setContactInfo = createAction(CONTACT_INFO);
export const setBillingInfo = createAction(BILLING_INFO);
export const setCart = createAction(CART);
export const setPayVia = createAction(PAY_VIA);
export const setPrevCartTotal = createAction(PREV_CART_TOTAL);
export const setCartTotal = createAction(CURRENT_CART_TOTAL);
export const setCartTotalWithDiscount = createAction(CART_TOTAL_WITH_DISCOUNT);
export const setDiscount = createAction(DISCOUNT);
export const setDiscountType = createAction(DISCOUNT_TYPE);
export const setPromoCode = createAction(PROMO_CODE);
export const setStripeToken = createAction(STRIPE_TOKEN);
export const setPayload = createAction(PAYLOAD);
export const resetDeviceOrder = createAction(RESET_DEVICE_ORDER);
export const setOrderId = createAction(ORDER_ID);
export const setPendingOrderData = createAction(PENDING_ORDER_DATA);
