import { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV3, apiUrlV4 } from 'src/services/constants/api-versions';
import {
  GetAllUsersPayload,
  GetAllUsersRes,
  GetUsersBySchoolIdRes,
  IListJoinRequest,
  IRequestSchool,
  IRole,
  ISetIsSchoolRequestStatusShown,
  IUpdateJoinRequest,
  IUpdateUserInfo,
} from 'src/services/interfaces/User.types';
import { ErrorRes } from 'src/types';
import { sortRoles } from 'src/util/sortRoles';

export const UserService = (axios: AxiosInstance) => {
  const GetUserDataByEmail = () => {
    const getUserDataByEmail = (email: string) => axios
      .get(`${apiUrlV4}user/data/${email}`)
      .then((res) => {
        if (!res.data.success) {
          snackbarService.error("Can't get user details. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      });

    return useMutation({
      mutationFn: getUserDataByEmail,
      mutationKey: ['get-user-data'],
      onError: (error) => {
        console.error('Error in get user data by email:', error);
        snackbarService.error('Your session has expired. Please sign in again.');
      },
    });
  };

  const GetUserDataById = () => {
    const getUserDataById = (userId: string) => axios
      .get(`${apiUrlV3}user/getUserData/${userId}`);

    return useMutation(
      getUserDataById,
      {
        onError: (error) => {
          console.error('Error in get user by id:', error);
          snackbarService.error('Something went wrong. Please, try later.');
        },
      },
    );
  };

  const GetCheckIfExists = () => {
    return useMutation(
      'mutation-check-if-exists',
      (payload: string) => axios
        .get(`${apiUrlV3}user/checkIfExists/${payload}`)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.error('Error when try to check if the user exists:', err);
          throw err;
        }),
    );
  };

  const GetJoinStatus = (email: string) => {
    const getJoinStatus = () => axios.get(`${apiUrlV4}user/join-status/${email}`);

    return useQuery(
      ['query-join-status', email],
      getJoinStatus,
      {
        refetchOnWindowFocus: false,
        refetchInterval: 7000,
        enabled: !!email,
      },
    );
  };

  const ListJoinRequest = () => {
    return useMutation(
      'mutation-list-join-request',
      (payload: IListJoinRequest) => axios
        .post(`${apiUrlV4}user/join-request`, payload)
        .then((res) => {
          return res;
        }),
    );
  };

  const useListJoinRequest = (payload: IListJoinRequest) => {
    const listJoinRequest = () => axios
      .post(`${apiUrlV4}user/join-request`, payload)
      .then((res) => {
        return res;
      });

    return useQuery({
      queryKey: ['query-list-join-request', payload],
      queryFn: listJoinRequest,
      onError: (error) => {
        console.error("Error in get user's list:", error);
        snackbarService.error("Can't get user's list. Please, try later.");
      },
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    });
  };

  const UpdateJoinRequest = () => {
    return useMutation(
      'mutation-update-join-request',
      ({
        user_id, super_admin_id, join_request, school_request, denial_reason,
      }: IUpdateJoinRequest) => axios
        .put(
          `${apiUrlV4}user/join-request/${user_id}`,
          {
            super_admin_id,
            join_request,
            school_request,
            denial_reason,
          },
        )
        .then((res) => {
          return res;
        }),
    );
  };

  const UpdateUserInfo = () => {
    return useMutation(
      'mutation-update-user',
      (payload: IUpdateUserInfo) => axios
        .put(`${apiUrlV4}user/${payload._id}`, payload)
        .then((res) => {
          if (!res.data.success) {
            snackbarService.error("Can't save changes. Please, try later.");
            throw res.data.message;
          }
          return res.data;
        })
        .catch((error) => {
          console.error('Error in update user info:', error);
          snackbarService.error("Can't save changes. Please, try later.");
          throw error;
        }),
    );
  };

  const GetUserRoles = () => {
    const getUserRoles = () => axios.get(`${apiUrlV3}userRole/`).then((res) => {
      const roles = res.data.data.map((r: IRole) => (
        {
          label: r.name,
          value: r._id,
        }
      ));
      return sortRoles(roles);
    });

    return useMutation(getUserRoles, {
      onError: (error) => {
        console.error("Error in get user's role list:", error);
        snackbarService.error("Can't get user's role list. Please, try later.");
      },
    });
  };

  const useGetAllUsers = (payload: GetAllUsersPayload) => {
    const getAllUsers = () => axios.post(`${apiUrlV4}user/get-all`, payload)
      .then((res) => {
        const resData: GetAllUsersRes = res.data;
        return resData;
      });

    return useQuery({
      queryKey: ['query-get-user-all', payload],
      queryFn: getAllUsers,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error('Error when try to get users:', error);
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: 2000,
      keepPreviousData: true,
    });
  };

  const useRequestSchool = () => {
    const requestSchool = (payload: IRequestSchool) => axios.put(
      `${apiUrlV4}user/school-request/${payload.user_id}`,
      { requested_schools: payload.requested_schools, created_on_app: payload.created_on_app },
    );

    return useMutation(requestSchool, {
      onSuccess: () => snackbarService.success('School request sent successfully'),
      onError: (error) => {
        console.error('Error in school request:', error);
        snackbarService.error("Can't make a request for schools. Please, try later.");
      },
    });
  };

  const useRemoveUserFromSchool = () => {
    const removeUserFromSchool = (payload) => axios.delete(`${apiUrlV4}user/remove`, {
      data: {
        user_id: payload.user_id,
        user_id_to_remove: payload.user_id_to_remove,
        schools_to_remove: payload.schools_to_remove,
      },
    }).then((res) => {
      if (res.status === 200) {
        if (!res.data || !res.data.success) {
          snackbarService.error('Error on removing user from school. Please try again later...');
          return;
        }
        // eslint-disable-next-line consistent-return
        return res.data.success;
      }
      // eslint-disable-next-line consistent-return
      return res;
    });

    return useMutation(removeUserFromSchool, {
      onSuccess: () => snackbarService.success('School removed successfully'),
      onError: (error) => {
        console.error('Error in remove school request:', error);
        snackbarService.error("Can't make a request for remove school. Please, try later.");
      },
    });
  };

  const useGetSchoolRequestStatus = (userId: string) => {
    const getSchoolRequestStatus = () => axios.get(`${apiUrlV4}user/school-request/${userId}`);

    return useQuery(
      ['query-request-school-status', userId],
      getSchoolRequestStatus,
      {
        onError: (error) => {
          snackbarService.error("Can't get school status request. Please, try later.");
          console.error('Error in get school status:', error);
        },
        refetchOnWindowFocus: true,
      },
    );
  };

  const useSetIsSchoolRequestStatusShown = () => {
    const setIsSchoolRequestStatusShown = (payload: ISetIsSchoolRequestStatusShown) => axios
      .patch(`${apiUrlV4}user/is-school-request-status-shown/${payload.userId}`, {
        is_school_request_status_shown: payload.is_school_request_status_shown,
      });
    return useMutation(setIsSchoolRequestStatusShown, {
      onError: (error) => {
        snackbarService.error("Can't set a request for school status. Please, try later.");
        console.error('Error in set a request for school status:', error);
      },
    });
  };

  const useGetUsersBySchoolId = (schoolId: string) => {
    const getUsersBySchoolId = () => axios
      .get(`${apiUrlV3}user/schoolUsers/${schoolId}`)
      .then((res) => {
        const resData: GetUsersBySchoolIdRes = {
          data: res.data.data.map((u) => ({
            label: `${u.first_name} ${u.last_name} (${u.email})`,
            value: u._id,
          })),
          success: res.data.success,
        };

        return resData;
      });

    return useQuery({
      queryKey: ['query-get-users-by-school-id', schoolId],
      queryFn: getUsersBySchoolId,
      onError: (error: ErrorRes) => {
        snackbarService.error("Can't save changes. Please, try later.");
        console.error('Error when get users by school id:', error);
      },
      enabled: !!schoolId,
      refetchOnWindowFocus: false,
    });
  };

  return {
    GetUserDataByEmail,
    GetUserDataById,
    GetCheckIfExists,
    GetJoinStatus,
    ListJoinRequest,
    useListJoinRequest,
    UpdateJoinRequest,
    UpdateUserInfo,
    GetUserRoles,
    useGetAllUsers,
    useRequestSchool,
    useRemoveUserFromSchool,
    useGetSchoolRequestStatus,
    useSetIsSchoolRequestStatusShown,
    useGetUsersBySchoolId,
  };
};
