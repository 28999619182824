import React, { lazy } from 'react';

const AuthLayout = lazy(() => import ('src/components/auth/auth-layout/AuthLayout'));
const SignUp = lazy(() => import('src/components/auth/sign-up/SignUp'));
const SignIn = lazy(() => import('src/components/auth/sign-in/SignIn'));
const ForgotPassword = lazy(() => import('src/components/auth/forgot-password/ForgotPassword'));
const ResendRequest = lazy(() => import('src/components/auth/resend-request/ResendRequest'));
const ResetPassword = lazy(() => import('src/components/auth/reset-password/ResetPassword'));
const DeviceOrder = lazy(() => import('src/components/customer/device-order/DeviceOrder'));

const publicRoutes = [
  {
    element: <AuthLayout />,
    children: [
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'reset-password/:email/:code',
        element: <ResetPassword />,
      },
      {
        path: 'resend-request',
        element: <ResendRequest />,
      },
      {
        path: 'device-order/:orderId?',
        element: <DeviceOrder />,
      },
    ],
    protected: false,
  },
];

export default publicRoutes;
