/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';
import {
  IPitchCountQuery, IPitchCountAdd, IPlayerParams, ICheckTotalPitches, GetPlayersByScheduleRes, PitchOutingsRes,
} from 'src/services/interfaces/PitchCount.interface';
import { ErrorRes } from 'src/types';
import dateFormat, { DateFormatOptions } from 'src/util/dateFormat/dateFormat';

const { YYYY_MM_DD } = DateFormatOptions;

export const PitchCountService = (axios: AxiosInstance) => {
  /**
   * Request to get pitch outings list
   * @param query
   * @returns array of pitch outings list
   */
  const currentUser = useSelector((state: any) => state.currentUser?.currentUser);

  const useGetPitchOutings = (payload: IPitchCountQuery) => {
    const getPitchOutings = async () => {
      try {
        const data = {
          ...payload,
          date: dateFormat(payload.date, YYYY_MM_DD),
          end_date: dateFormat(payload.end_date, YYYY_MM_DD),
          offset: new Date().getTimezoneOffset(),
        };

        const res = await axios.post(`${apiUrlV4}pitcherOuting/getPitcherOutings`, data);
        const resData: PitchOutingsRes = res.data;

        return resData;
      } catch (error) {
        return console.error('Error in get schools by email:', error);
      }
    };

    return useQuery(
      {
        queryKey: ['query-get-pitcher-outing', payload],
        queryFn: getPitchOutings,
        onError: () => {
          snackbarService.error("Can't get pitch outings. Please, try later.");
        },
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 60,
        keepPreviousData: true,
      },
    );
  };

  const useAddPitchOuting = () => {
    const addPitchOuting = (payload: IPitchCountAdd) => axios.put(`${apiUrlV4}pitcherOuting/save`, payload);
    return useMutation(addPitchOuting, {
      onSuccess: () => {
        snackbarService.success('Pitch outing saved successfully!');
      },
      onError: (error) => {
        snackbarService.error("Can't save pitch outing. Please, try later.");
        console.error('Error when try to save pitch outing:', error);
      },
    });
  };

  const useAddPitchOutingList = () => {
    const addPitchOutingList = (payload: Array<IPitchCountAdd>) => axios.put(`${apiUrlV4}pitcherOuting/save-list`, payload);

    return useMutation(addPitchOutingList, {
      onSuccess: () => {
        snackbarService.success('Pitch outings saved successfully!');
      },
      onError: (error) => {
        snackbarService.error("Can't save pitch outings. Please, try later.");
        console.error('Error when try to save pitch outings:', error);
      },
    });
  };

  const useDeletePitchOuting = () => {
    const deletePitchOuting = (id: string) => {
      return axios
        .post(`${apiUrlV4}pitcherOuting/delete`, { pitcherOutings: [id], deleted_by: currentUser._id })
        .then((res: any) => {
          if (!res.data.success) {
            snackbarService.error("Can't get pitch outings. Please, try later.");
            throw res.data.message;
          }
          return res.data;
        });
    };

    return useMutation(deletePitchOuting, {
      onError: (error) => {
        console.error('Error in get schools by email:', error);
        snackbarService.error("Can't get pitch outings. Please, try later.");
        throw error;
      },
    });
  };

  const useGetPitchersBySchool = () => {
    const getPitchersBySchool = (id: string) => axios
      .get(`${apiUrlV4}player/players/${id}`)
      .then((res: any) => {
        if (!res.data.success) {
          snackbarService.error("Can't get pitchers by school. Please, try later.");
          throw res.data.message;
        }
        return res.data;
      });

    return useMutation(getPitchersBySchool, {
      onError: (error) => {
        console.error('Error when try to get pitchers by school:', error);
        snackbarService.error("Can't get pitchers by school. Please, try later.");
        throw error;
      },
    });
  };

  const getPlayersBySchedule = (scheduleId: string) => axios
    .get(`${apiUrlV4}player/players/by-schedule/${scheduleId}`)
    .then((res) => {
      const formatPlayers: GetPlayersByScheduleRes = res.data?.players?.map((school) => {
        const updatedData = school.data?.map((player) => {

          return {
            value: player._id,
            label: `#${player.jersey_number} ${player.name}`,
            schoolId: school.id,
          };
        });

        return {
          options: updatedData,
          value: school.id,
          label: school.name,
        };
      });

      return formatPlayers;
    });

  const useGetPlayersBySchedule = (scheduleId: string) => {
    return useQuery({
      queryKey: ['query-get-players-by-schedule', scheduleId],
      queryFn: () => getPlayersBySchedule(scheduleId),
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
      refetchOnWindowFocus: false,
      enabled: !!scheduleId,
    });
  };

  const useGetPlayersByScheduleMutation = () => {
    return useMutation({
      mutationKey: ['mutation-get-players-by-schedule'],
      mutationFn: getPlayersBySchedule,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
    });
  };

  const useAddNewPlayer = () => {
    const addNewPlayer = (data: IPlayerParams) => {
      return axios
        .post(`${apiUrlV4}player`, data)
        .then((res: any) => {
          if (!res.data.success) {
            throw res.data.message;
          }
          return res.data;
        });
    };

    return useMutation(addNewPlayer, {
      onSuccess: () => snackbarService.success('New player successfully added!'),
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
    });
  };

  const useCheckTotalPitches = () => {
    const checkTotalPitches = (payload: ICheckTotalPitches) => axios.post(`${apiUrlV4}pitcherOuting/check-total-pitches`, payload);

    return useMutation(
      checkTotalPitches,
      {
        onError: (error) => {
          snackbarService.error("Can't check total pitches. Please, try later.");
          console.error('Error when try to check total pitches:', error);
        },
      },
    );
  };

  const useCheckTotalPitchesList = () => {
    const checkTotalPitchesList = (payload: ICheckTotalPitches[]) => axios.post(`${apiUrlV4}pitcherOuting/check-total-pitches-list`, payload);

    return useMutation(
      checkTotalPitchesList,
      {
        onError: (error) => {
          snackbarService.error("Can't check total pitches. Please, try later.");
          console.error('Error when try to check total pitches:', error);
        },
      },
    );
  };

  return {
    useGetPitchOutings,
    useAddPitchOuting,
    useAddPitchOutingList,
    useDeletePitchOuting,
    useGetPitchersBySchool,
    useGetPlayersBySchedule,
    useGetPlayersByScheduleMutation,
    useAddNewPlayer,
    useCheckTotalPitches,
    useCheckTotalPitchesList,
  };
};
