import React, { useEffect, useState } from 'react';

import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import logoIcon from 'src/assets/images/logo/logo-icon-new.svg';
import Basketball from 'src/assets/images/waiting-room/basketball.gif';
import Button from 'src/components/common/button/CustomButton';
import ConfirmationModalNew from 'src/components/common/modals/confirmation-modal-new/ConfirmationModalNew';
import ErrorModalNew from 'src/components/common/modals/error-modal-new/ErrorModalNew';
import MessageModalNew from 'src/components/common/modals/message-modal-new/MessageModalNew';
import { StatusEnums } from 'src/enums/StatusEnums';
import api from 'src/services/api';
import { ActionTypes } from 'src/store/reducers/currentUserReducer';
import { useAppSelector } from 'src/store/store';

import { ILocationState, IConfirmationModal } from './WaitingRoom.interface';
import styles from './WaitingRoom.module.css';

const { AWAITING, ACCEPTED, DENIED } = StatusEnums;
const { CURRENT_USER_IS_USER_APPROVED, CURRENT_USER_EDIT_SIGN_UP_USER, CURRENT_USER_EDIT_RESEND_REQUEST } = ActionTypes;

const WaitingRoom = () => {
  const navigate = useNavigate();
  const location = useLocation() as ILocationState;
  const dispatch = useDispatch();
  const { editSignUpUser, editResendRequest } = useAppSelector((store) => store.currentUser);
  const { isAuth, authLoaded } = useAppSelector((store) => store.token);
  const { GetJoinStatus } = api.userService();
  const { data: joinStatusData } = GetJoinStatus(editSignUpUser?.email || editResendRequest?.email);
  // @ts-ignore
  const [openDeniedModal, setOpenDeniedModal] = useState<boolean>(editResendRequest?.join_request === DENIED);
  const [openContactModal, setOpenContactModal] = useState<boolean>(false);
  const [showEditUserInfoBtn, setShowEditUserInfoBtn] = useState<boolean>(true);
  const [confirmationModal, setConfirmationModal] = useState<IConfirmationModal>({
    open: false,
    title: '',
  });

  useEffect(() => {
    if (localStorage.getItem('displayedEditUserInfoBtn')) {
      setShowEditUserInfoBtn(false);
    } else {
      localStorage.setItem('displayedEditUserInfoBtn', 'true');
    }
  }, []);

  useEffect(() => {
    if (!authLoaded || !isAuth) return;

    if (joinStatusData?.data.join_request === ACCEPTED) {
      localStorage.setItem('user', editSignUpUser?.email || editResendRequest?.email);

      dispatch({
        type: CURRENT_USER_IS_USER_APPROVED,
        payload: true,
      });
      dispatch({
        type: CURRENT_USER_EDIT_SIGN_UP_USER,
        payload: null,
      });
      dispatch({
        type: CURRENT_USER_EDIT_RESEND_REQUEST,
        payload: null,
      });

      navigate('/');
    }

    if (joinStatusData?.data.join_request === AWAITING) {
      setOpenDeniedModal(false);
    }

    if (joinStatusData?.data.join_request === DENIED) {
      // Clear the values when the user's status changes from AWAITING to DENIED
      dispatch({
        type: CURRENT_USER_EDIT_SIGN_UP_USER,
        payload: null,
      });
      setOpenDeniedModal(true);
    }
  }, [joinStatusData, isAuth, authLoaded]);

  const handleCloseDeniedModal = () => {
    // Clear the values when the user Signs In and their status is DENIED
    if (editResendRequest?.join_request === DENIED) {
      dispatch({
        type: CURRENT_USER_EDIT_RESEND_REQUEST,
        payload: null,
      });
    }
    setOpenDeniedModal(false);
  };

  const handleContactSupport = () => {
    setOpenContactModal(true);
  };

  return (
    <div className={styles.authContainer}>
      <div className={styles.authMainWrapper}>
        <div className={styles.leftWrapper}>
          <img className={styles.basketball} src={Basketball} alt="basketball-gif" />
        </div>
        <div className={styles.rightWrapper}>
          <div className={styles.rightContent}>
            <Button
              version="transparent"
              className={styles.backBtn}
              tooltip={
                joinStatusData?.data.join_request === DENIED
                  ? 'Go to Sign In Page'
                  : location.state?.prevUrl === '/sign-up'
                    ? 'Go to Edit Sign Up Page'
                    : location.state?.prevUrl === '/resend-request'
                      ? 'Go to Resend Request Page'
                      // When the user navigates from the Sign In Page
                      : 'Go to Sign In Page'
              }
              onClick={() => {
                // When the status changes AWAITING => DENIED in the Waiting Room
                if (joinStatusData?.data.join_request === DENIED) {
                  return navigate('/sign-in');
                }
                if (location.state?.prevUrl === '/sign-up' || location.state?.prevUrl === '/resend-request') {
                  return setConfirmationModal({
                    open: true,
                    title: !(location.state?.prevUrl === '/sign-up')
                      ? 'Are you sure you want to go back to editing your resend request information?'
                      : 'Are you sure you want to go back to editing your sign up information?',
                  });
                }
                // When the user navigates from the Sign In Page
                return navigate(-1);
              }}
            >
              <FontAwesomeIcon size="3x" icon={faAngleLeft} />
            </Button>
            <div>
              <img className={styles.logoIcon} src={logoIcon} alt="Scorebird_logo_icon" />
            </div>

            <div>
              <h1 className={styles.title}>Waiting room</h1>
              <span className={styles.accessText}>Your access request is being processed. New accounts are generally approved within 24 hours.</span>
              <div className={styles.actions}>

                {((joinStatusData?.data.join_request === AWAITING) && showEditUserInfoBtn) && (
                  <Button
                    className={styles.editBtn}
                    onClick={() => setConfirmationModal({
                      open: true,
                      title: 'Are you sure you want to go back to editing your sign up information?',
                    })}
                  >
                    Edit Sign Up Info
                  </Button>
                )}

                <Button className={styles.contactBtn} onClick={() => setOpenContactModal(true)}>Contact Support</Button>
              </div>
            </div>

            <div className={styles.footerContent}>
              <span className={styles.accountText}>Already have an account? </span>
              <Button
                className={styles.signInBtn}
                version="transparent"
                onClick={() => {
                  dispatch({
                    type: CURRENT_USER_EDIT_SIGN_UP_USER,
                    payload: null,
                  });
                  dispatch({
                    type: CURRENT_USER_EDIT_RESEND_REQUEST,
                    payload: null,
                  });
                  return navigate('/sign-in');
                }}
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </div>
      {confirmationModal.open && (
        <ConfirmationModalNew
          title={confirmationModal.title}
          text="Your access request will not be canceled"
          approveBtnTitle="Yes, go back"
          approveFn={() => {
            if (editSignUpUser) {
              // Edit Sign Up case
              navigate('/sign-up');
              setConfirmationModal({
                open: false,
                title: '',
              });
            } else {
              navigate('/resend-request');
              setConfirmationModal({
                open: false,
                title: '',
              });
              handleCloseDeniedModal();
            }
          }}
          cancelFn={() => setConfirmationModal({ open: false, title: '' })}
        />
      )}

      {openContactModal && (
        <MessageModalNew
          text={'Having Issues Connecting?\n Contact Us.'}
          cancelFn={() => setOpenContactModal(false)}
        />
      )}

      {openDeniedModal && (
        <ErrorModalNew
          setOpenDeniedModal={setOpenDeniedModal}
          contactSupportFn={handleContactSupport}
        />
      )}
    </div>
  );
};
export default WaitingRoom;
