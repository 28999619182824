import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';
import { ErrorRes } from 'src/types';

import { GetAllTeamGradesRes } from '../interfaces/Grade.types';

export const GradeService = (axios:AxiosInstance) => {
  /**
   * Get all grades
   * Save grades in REDUX store
   */

  const useGetAllTeamGrades = () => {
    const getAllTeamGrades = () => axios
      .get(`${apiUrlV4}team-grades`)
      .then((res) => {
        const resData: GetAllTeamGradesRes = {
          data: res.data.data.map((grade) => ({ label: grade.display_name, value: grade._id })),
          success: res.data.success,
        };

        return resData;
      });

    return useQuery({
      queryKey: ['get-team-grades'],
      queryFn: getAllTeamGrades,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
      refetchOnWindowFocus: false,
    });
  };

  return {
    useGetAllTeamGrades,
  };
};
