import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import api from 'src/services/api';

export const useFetchGrades = () => {
  const dispatch = useDispatch();
  const { useGetAllTeamGrades } = api.gradeService();
  const { data, isLoading: isFetching } = useGetAllTeamGrades();

  useEffect(() => {
    dispatch({
      type: 'grades/setGrades',
      payload: data?.data,
    });
  }, [data]);

  return { isFetching };
};
