import React from 'react';

import {
  useRoutes, Navigate,
} from 'react-router-dom';

import adminRoutes from './config/adminRoutes';
import publicRoutes from './config/publicRoutes';
import superAdminRoutes from './config/superAdminRoutes';
import { Route } from './ScoreBirdRouter.types';

const ScoreBirdRouter = ((props: { isAuth: boolean, isSuperAdmin: boolean }) => {
  const { isAuth, isSuperAdmin } = props;

  const routes = isSuperAdmin ? superAdminRoutes : adminRoutes;
  const allRoutes: Route[] = [...publicRoutes, ...routes];

  return useRoutes(
    allRoutes.map((route) => ({
      path: route.path,
      element: isAuth || !route?.protected ? route.element : <Navigate to="/sign-in" replace />,
      children: route?.children,
    })),
  );
});

export default ScoreBirdRouter;
