import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { GameCategory, GamesAdminData, MOCK_GAMES_ADMIN_DATA } from 'src/interfaces/GamesAdmin';

interface Store {
  loading: boolean;
  gamesAdminData: GamesAdminData;
}

const initialState: Store = {
  loading: false,
  gamesAdminData: null,
};

export const fetchGamesAdminData = createAsyncThunk<GamesAdminData, void>('gamesAdmin/fetchGamesAdminData', async () => {
  return new Promise<GamesAdminData>((resolve) => {
    setTimeout(
      () => {
        resolve(MOCK_GAMES_ADMIN_DATA);
      },
      1000
    );
  });
});

interface UpdateGameFlaggedParams {
  id: string;
  flagged: boolean;
  category: GameCategory;
}

export const updateGameFlagged = createAsyncThunk<UpdateGameFlaggedParams, UpdateGameFlaggedParams>('gamesAdmin/updateGameFlagged', async ({ id, flagged, category }) => {
  return { id, flagged, category };
});

interface UpdateNoAutoFinalizeParams {
  id: string;
  noAutoFinalize: boolean;
  category: GameCategory;
}

export const updateNoAutoFinalize = createAsyncThunk<UpdateNoAutoFinalizeParams, UpdateNoAutoFinalizeParams>('gamesAdmin/updateNoAutoFinalize', async ({ id, noAutoFinalize, category }) => {
  return { id, noAutoFinalize, category };
});

export const gamesAdminReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchGamesAdminData.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchGamesAdminData.fulfilled, (state, action) => {
    state.loading = false;
    state.gamesAdminData = action.payload;
  });
  builder.addCase(fetchGamesAdminData.rejected, (state) => {
    state.loading = false;
  });

  builder.addCase(updateGameFlagged.fulfilled, (state, action) => {
    const { id, flagged, category } = action.payload;
    const index = state.gamesAdminData[category].findIndex((game) => game.id === id);
    if (index !== -1) {
      state.gamesAdminData[category][index].flagged = flagged;
    }
  });

  builder.addCase(updateNoAutoFinalize.fulfilled, (state, action) => {
    const { id, noAutoFinalize, category } = action.payload;
    const index = state.gamesAdminData[category].findIndex((game) => game.id === id);
    if (index !== -1) {
      state.gamesAdminData[category][index].noAutoFinalize = noAutoFinalize;
    }
  });
});
