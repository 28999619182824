import { createAction, createReducer } from '@reduxjs/toolkit';

interface IToken {
  token: string | null;
  isAuth: boolean;
  authLoaded: boolean;
}

/* Initial state */
const initialState: IToken = {
  token: null,
  isAuth: false,
  authLoaded: false,
};

/* Actions */
const setToken = createAction('token/set');
const setIsAuth = createAction('token/setIsAuth');
const setAuthLoaded = createAction('token/setAuthLoaded');

/* Reducer */
export const tokenReducer = createReducer(initialState, (builder) => {
  builder.addCase(setToken, (state, action) => {
    state.token = action.payload;
  });
  builder.addCase(setIsAuth, (state, action) => {
    state.isAuth = action.payload;
  });
  builder.addCase(setAuthLoaded, (state, action) => {
    state.authLoaded = action.payload;
  });
});
