import { AxiosInstance } from 'axios';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV3, apiUrlV4 } from 'src/services/constants/api-versions';
import { ErrorRes } from 'src/types';
import dateFormat from 'src/util/dateFormat/dateFormat';

import {
  DevicesServicePayload,
  GetDeviceSerialsPayload,
  GetDeviceSerialsRes,
  GetDevicesPayload,
  GetDevicesRes,
  GetDevicesPageParam,
  SetupDevicePayload,
  DeviceSavePayload,
  DeviceSaveRes,
  GetDeviceAllPayload,
  SaveDeviceDetailsPayload,
  GetDeviceDetailRes,
  GetDevicesAllRes,
  WifiSetupPayload,
  BoxCastStreamSourcesRes,
  GetNestTypesRes,
  GetAvailableDevicesRes,
} from '../interfaces/Devices.types';

export const DevicesService = (axios:AxiosInstance) => {
  const fetchDevices = (payload:DevicesServicePayload) => {
    /*
    * @param {Object} payload
    * @returns {Promise}
    * @description Get all devices
    */
    const params = {
      ...payload,
      offset: -180,
    };
    return axios.get(`${apiUrlV3}device/getAll`, { params }).then((res) => {
      if (res.status === 200 && res.data.success) {
        return res.data;
      }
      throw new Error('Devices request error, please try again later.');

    }).catch((err) => {
      console.error('Error fetching devices:', err);
      snackbarService.error('Error fetching devices, try page reloading.');
      throw err;
    });
  };

  const SetupDevice = () => {
    return useMutation(
      'mutation-setup-device',
      (payload: SetupDevicePayload) => axios
        .post(`${apiUrlV3}nestCoudShadow`, payload)
        .then((res) => {
          if (!res.data?.shadow.success) {
            snackbarService.error('Something went wrong. Please, try later.');
            console.error('Error when try to setup device', res.data.shadow.message);
          }
          snackbarService.success('Device updated!');
          return res.data.shadow;
        })
        .catch((err) => {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error when try to setup device', err);
        }),
    );
  };

  const GetDeviceData = () => {
    const getDeviceData = (params: GetDeviceAllPayload) => {
      return axios
        .post(`${apiUrlV4}device/getAll`, params)
        .then((res) => {
          const devices = res.data.data;
          const devicesData = [];
          const devicesById = {};
          if (!res.data.data) {
            return {
              totalCount: 0, page: 1, devicesData, success: true,
            };
          }
          devices.forEach((device) => {
            const deviceRow = {
              device_name: device.device_name ? device.device_name : '--',
              device_id: device.serial ? device.serial : '--',
              group: device.groups ? device.groups.join(', ') : '--',
              group_names: '--',
              sport: device?.sport,
              score_board: device.score_board || '--',
              last_online_ts: device.last_online_ts ? device.last_online_ts : '--',
              notes: device.notes ? device.notes : '- -',
              online: device.online,
              facility:
                  device.facility_data
                  && device.facility_data.length
                  && device.facility_data[device.facility_data.length - 1].name
                    ? device.facility_data[device.facility_data.length - 1].name
                    : '--',
              facility_id:
                  device.facility_data && device.facility_data.length
                    ? device.facility_data[device.facility_data.length - 1]._id
                    : '',
              school: device.school_data ? device.school_data.name : '--',
              shadow: device.shadow ? device.shadow : '',
              paymentMethod: device.paymentMethod,
              shippingStatus: device.shippingStatus,
            };
            devicesData.push(deviceRow);
            devicesById[device.serial] = deviceRow;
          });
          return {
            totalCount: res.data.totalCount,
            page: res.data.page,
            devicesData,
            success: true,
          };
        });
    };

    return useMutation(
      getDeviceData,
      {
        onError: (error) => {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error while trying to get list of devices:', error);
        },
      },
    );
  };

  const useDeviceSave = () => {
    const deviceSave = (payload: DeviceSavePayload) => {
      const {
        serial,
        nest_type_id,
        shadow,
        facility_id,
        sport,
        sport_ids,
        notes,
        test_sport,
        test_model,
        swap_home,
        runnable_outputs,
        unset_facility = false,
        unset_allowed_sports = false,
      } = payload;
      return axios.put(`${apiUrlV4}device/save/${serial}`, {
        shadow,
        nest_type_id,
        facility_id,
        sport,
        sport_ids,
        notes,
        test_sport,
        test_model,
        swap_home,
        runnable_outputs,
        unset_facility,
        unset_allowed_sports,
      })
        .then((res) => {
          const resData: DeviceSaveRes = res.data;
          return resData;
        });
    };

    return useMutation({
      mutationKey: 'mutation-save-device',
      mutationFn: deviceSave,
      onSuccess: () => {
        snackbarService.success('Saved successfully');
      },
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
    });
  };

  const useGetDeviceDetail = (deviceId: string) => {
    const getDeviceDetail = () => axios
      .get(`${apiUrlV4}device/detail/${deviceId}`)
      .then((res) => {
        const resData: GetDeviceDetailRes = res.data;
        return resData;
      });

    return useQuery({
      queryKey: ['query-get-device-detail', deviceId],
      queryFn: getDeviceDetail,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
      refetchOnWindowFocus: false,
    });
  };

  const useGetDevicesAll = (payload: GetDeviceAllPayload) => {
    const getDevices = () => axios
      .post(`${apiUrlV4}device/get-all`, payload)
      .then((res) => {
        const resData: GetDevicesAllRes = res.data;
        return resData;
      });

    return useQuery({
      queryKey: ['query-devices-get-all', payload],
      queryFn: getDevices,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
      staleTime: Infinity,
      cacheTime: 2000,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    });
  };

  const useSaveDeviceDetails = () => {
    const saveDeviceDetails = (payload: SaveDeviceDetailsPayload) => axios
      .put(`${apiUrlV3}device/save/${payload.serial}`, payload)
      .then((res) => res.data);

    return useMutation({
      mutationKey: ['mutation-device-save'],
      mutationFn: saveDeviceDetails,
      onSuccess: () => {
        snackbarService.success('Note saved successfully');
      },
      onError: () => {
        snackbarService.error('Error in saving note');
      },
    });
  };

  const useGetDeviceSerials = () => {
    const getDeviceSerials = (payload: GetDeviceSerialsPayload) => axios
      .post(`${apiUrlV4}device/serials`, payload)
      .then((res) => {
        const resData: GetDeviceSerialsRes = res.data;
        return resData;
      });

    return useMutation(
      getDeviceSerials,
      {
        onError: (error) => {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error while trying to get list of device serials:', error);
        },
      },
    );
  };

  const useGetDevices = (payload: GetDevicesPayload) => {
    const getDevices = ({ pageParam = null }) => {
      const datePayload = {
        date: dateFormat(payload.date, 'YYYY-MM-DD'),
        end_date: dateFormat(payload.end_date, 'YYYY-MM-DD'),
      };

      const pagePayload: GetDevicesPageParam = pageParam || {
        page_size: 10,
        previous: {
          serial: '',
          online: true,
        },
      };

      return axios
        .post(`${apiUrlV4}device/game-day-nests`, { ...payload, ...datePayload, ...pagePayload })
        .then((res: GetDevicesRes) => res);
    };

    return useInfiniteQuery(
      {
        queryKey: ['get-devices', payload],
        queryFn: getDevices,
        getNextPageParam: (lastPage) => {
          const isLastPage = lastPage.data?.is_last_page;

          if (isLastPage) {
            return false;
          }

          const lastDevice = lastPage.data.data[lastPage.data.data.length - 1];

          return {
            page_size: 10,
            previous: {
              serial: lastDevice?.serial,
              online: lastDevice.online,
            },
          };
        },
        onError: (error: ErrorRes) => {
          snackbarService.error(error.response.data.message);
          console.error(error.response.data.message);
        },
        refetchOnWindowFocus: false,
        cacheTime: 500,
      },
    );
  };

  const useDeviceReboot = () => {
    const deviceReboot = (deviceSerial: string) => axios
      .put(`${apiUrlV4}device/${deviceSerial}/reboot`)
      .then((res) => res.data);

    return useMutation({
      mutationKey: ['mutation-device-reboot'],
      mutationFn: deviceReboot,
      onSuccess: (data) => {
        snackbarService.success(data.message);
      },
      onError: (data) => {
        // @ts-ignore
        snackbarService.error(data?.message);
      },
    });
  };

  const useWifiSetup = () => {
    const wifiSetup = (payload: WifiSetupPayload) => axios
      .post(`${apiUrlV4}device/wifi`, payload)
      .then((res) => res.data);

    return useMutation({
      mutationKey: ['mutation-device-wifi'],
      mutationFn: wifiSetup,
      onSuccess: (data) => {
        snackbarService.success(data.message);
      },
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
    });
  };

  const useGetBoxCastStreamSources = () => {
    const getBoxCastStreamSources = () => axios
      .get(`${apiUrlV4}device/boxcast-stream-sources`)
      .then((res) => {
        const resData: BoxCastStreamSourcesRes = {
          data: res.data.data.map(({ id, _id, name }) => ({ id, value: _id, label: name })),
          success: res.data.success,
        };
        return resData;
      });

    return useQuery({
      queryKey: ['get-box-cast-stream-sources'],
      queryFn: getBoxCastStreamSources,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
      refetchOnWindowFocus: false,
    });
  };

  const useGetNestTypes = () => {
    const getNestTypes = () => axios
      .get(`${apiUrlV4}device/nest-types`)
      .then((res) => {
        const resData: GetNestTypesRes = {
          data: res.data.data.map(({ _id, display_name }) => ({ value: _id, label: display_name })),
          success: res.data.success,
        };
        return resData;
      });

    return useQuery({
      queryKey: ['get-nest-types'],
      queryFn: getNestTypes,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
      refetchOnWindowFocus: false,
      cacheTime: 0,
    });
  };

  const useGetAvailableDevices = () => {
    const getAvailableDevices = () => axios
      .get(`${apiUrlV3}device/getAvailableDevices`)
      .then((res) => {
        const devicesMapped = res.data?.data.filter((d) => d !== null)
          .map((device) => ({ label: device, value: device }));
        const noDevice = { label: 'No device', value: null };
        devicesMapped.unshift(noDevice);

        const resData: GetAvailableDevicesRes = {
          data: devicesMapped,
          success: res.data.success,
        };
        return resData;
      });

    return useQuery({
      queryKey: ['get-available-devices'],
      queryFn: getAvailableDevices,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
      refetchOnWindowFocus: false,
    });
  };

  return {
    fetchDevices,
    SetupDevice,
    GetDeviceData,
    useDeviceSave,
    useGetDeviceDetail,
    useGetDevicesAll,
    useSaveDeviceDetails,
    useGetDeviceSerials,
    useGetDevices,
    useDeviceReboot,
    useWifiSetup,
    useGetBoxCastStreamSources,
    useGetNestTypes,
    useGetAvailableDevices,
  };
};
