import { createAction, createReducer } from '@reduxjs/toolkit';

interface Grades {
  grades: any[],
  detailedList: any[],
}

/* Initial state */
const initialState: Grades = {
  grades: [],
  detailedList: [],
};

/* Actions */
const setGrades = createAction('grades/setGrades', (grades: any[]) => ({
  payload: grades,
}));

/* Reducer */
export const gradesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setGrades, (state, action) => {
    state.grades = action.payload;
  });
});
