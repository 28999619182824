import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import api from 'src/services/api';
import {
  ConnectionType, GameStatus, LastUpdate, Shadow
} from 'src/services/api/FiltersService';

interface Store {
  connectionTypes: ConnectionType[];
  periodValues: number[];
  gameStatuses: GameStatus[];
  lastUpdates: LastUpdate[];
  shadows: Shadow[];
}
const initialState: Store = {
  connectionTypes: null,
  periodValues: null,
  gameStatuses: null,
  lastUpdates: null,
  shadows: null,
};

export const fetchConnectionTypes = createAsyncThunk('filters/fetchConnectionTypes', async (_, { getState }) => {
  const state = getState() as { filters: Store };
  return state.filters.connectionTypes ?? await api.filtersService().getConnectionTypes();
});

export const fetchPeriodValues = createAsyncThunk('filters/fetchPeriodValues', async (_, { getState }) => {
  const state = getState() as { filters: Store };
  return state.filters.periodValues ?? await api.filtersService().getPeriodValues();
});

export const fetchGameStatuses = createAsyncThunk('filters/fetchGameStatuses', async (_, { getState }) => {
  const state = getState() as { filters: Store };
  return state.filters.gameStatuses ?? await api.filtersService().getGameStatuses();
});

export const fetchLastUpdates = createAsyncThunk('filters/fetchLastUpdates', async (_, { getState }) => {
  const state = getState() as { filters: Store };
  return state.filters.lastUpdates ?? await api.filtersService().getLastUpdates();
});

export const fetchShadows = createAsyncThunk('filters/fetchShadows', async (_, { getState }) => {
  const state = getState() as { filters: Store };
  return state.filters.shadows ?? await api.filtersService().getShadows();
});

export const filtersReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchConnectionTypes.fulfilled, (state, action) => {
    state.connectionTypes = action.payload;
  });
  builder.addCase(fetchPeriodValues.fulfilled, (state, action) => {
    state.periodValues = action.payload;
  });
  builder.addCase(fetchGameStatuses.fulfilled, (state, action) => {
    state.gameStatuses = action.payload;
  });
  builder.addCase(fetchLastUpdates.fulfilled, (state, action) => {
    state.lastUpdates = action.payload;
  });
  builder.addCase(fetchShadows.fulfilled, (state, action) => {
    state.shadows = action.payload;
  });
});
