export enum deviceOrderActionTypes {
  CONTACT_INFO = 'deviceOrder/contactInfo',
  BILLING_INFO = 'deviceOrder/billingInfo',
  CART = 'deviceOrder/cart',
  PAY_VIA = 'deviceOrder/payVia',
  PREV_CART_TOTAL = 'deviceOrder/prevCartTotal',
  CURRENT_CART_TOTAL = 'deviceOrder/currentCartTotal',
  CART_TOTAL_WITH_DISCOUNT = 'deviceOrder/cartTotalWithDiscount',
  PROMO_CODE = 'deviceOrder/promoCode',
  DISCOUNT = 'deviceOrder/discount',
  DISCOUNT_TYPE = 'deviceOrder/discountType',
  STRIPE_TOKEN = 'deviceOrder/stripeToken',
  PAYLOAD = 'deviceOrder/payload',
  RESET_DEVICE_ORDER = 'deviceOrder/resetDeviceOrder',
  ORDER_ID = 'deviceOrder/orderId',
  PENDING_ORDER_DATA = 'deviceOrder/pendingOrderData',
}
